import { BaseResponseType, CreateDevice, FetchDevicesResponseType, UpdateDevice } from "../@types/Responses";
import { deleteOptions, getOptions, postOptions, putOptions } from "./common";

const endpoint = '/api/devices';

const fetchDevices = async () : Promise<FetchDevicesResponseType> => {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, getOptions());

  return response.json();
}

const fetchDevicesByUserId = async (userId: number) : Promise<FetchDevicesResponseType> => {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}?userId=${userId}`, getOptions());

  return response.json();
}

const updateDeviceById = async (device: UpdateDevice) : Promise<BaseResponseType> => {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/${device.id}`, putOptions(JSON.stringify(device)));
  return response.json();
}

const createDevice = async (device: CreateDevice) : Promise<BaseResponseType> => {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}`, postOptions(JSON.stringify(device)));
  return response.json();
}

const deleteDevice = async (deviceId: number) : Promise<BaseResponseType> => {
  const response = await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_URL_NAME}${endpoint}/${deviceId}`, deleteOptions());
  return response.json();
}

export const device = {
  fetchDevices,
  fetchDevicesByUserId,
  updateDeviceById,
  createDevice,
  deleteDevice
};