import { Route, Routes } from 'react-router-dom';
import App from './App';
import MapLayout from './components/Common/MapLayout';
import Login from './components/Login/Login';
import MainMap from './components/MainMap/MainMap';
import RouteMap from './components/RouteMap/RouteMap';
import NotFound from './components/Common/NotFound';
import TripMap from './components/TripMap/TripMap';
import DeviceScreen from './components/Device/DeviceScreen';
import UserScreen from './components/User/UserScreen';
import Report from './components/Report/Report';
import Theme from './components/Common/Theme';
import NotificationScreen from './components/Notification/NotificationScreen';
import { useAuthContext } from './contexts/AuthenticationContext';
import Support from './Support';
import Geofence from './components/Geofence/Geofence';

const Navigation = () => {
  const { user } = useAuthContext();

  if (!user) {
    return (
      <Theme>
      <Routes>
        <Route path='/login' element={<Login/>}/>
        <Route path='/' element={<NotFound/>}/>
        <Route path='/device' element={<NotFound/>} />
        <Route path='/user' element={<NotFound/>} />
        <Route path='/report' element={<NotFound/>} />
        <Route path='/notification' element={<NotFound/>} />
        <Route path='/support' element={<Support/>} />
        <Route path='/geofence' element={<NotFound/>} />
        <Route path='/map' element={<NotFound/>}>
          <Route element={<NotFound/>}>
            <Route index element={<NotFound/>} />
            <Route path='route' element={<NotFound/>} />
            <Route path='trip' element={<NotFound/>} />
          </Route>
        </Route>
        <Route path='*' element={<NotFound/>} />
      </Routes>
      </Theme>
    )
  }
  else if (user.isClient) {
    return (
      <Theme>
      <Routes>
        <Route path='/login' element={<Login/>}/>
        <Route path='/' element={<NotFound/>}/>
        <Route path='/device' element={<NotFound/>} />
        <Route path='/user' element={<NotFound/>} />
        <Route path='/report' element={<NotFound/>} />
        <Route path='/notification' element={<NotificationScreen/>} />
        <Route path='/support' element={<Support/>} />
        <Route path='/geofence' element={<Geofence/>} />
        <Route path='/map' element={<App/>}>
          <Route element={<MapLayout/>}>
            <Route index element={<MainMap/>} />
            <Route path='route' element={<RouteMap/>} />
            <Route path='trip' element={<NotFound/>} />
          </Route>
        </Route>
        <Route path='*' element={<NotFound/>} />
      </Routes>
      </Theme>
    )
  }
  else {
    return (
      <Theme>
      <Routes>
        <Route path='/login' element={<Login/>}/>
        <Route path='/' element={<NotFound/>}/>
        <Route path='/device' element={<DeviceScreen/>} />
        <Route path='/user' element={<UserScreen/>} />
        <Route path='/report' element={<Report/>} />
        <Route path='/notification' element={<NotFound/>} />
        <Route path='/support' element={<Support/>} />
        <Route path='/geofence' element={<NotFound/>} />
        <Route path='/map' element={<App/>}>
          <Route element={<MapLayout/>}>
            <Route index element={<MainMap/>} />
            <Route path='route' element={<RouteMap/>} />
            <Route path='trip' element={<NotFound/>} />
          </Route>
        </Route>
        <Route path='*' element={<NotFound/>} />
      </Routes>
      </Theme>
    );
  }
};

export default Navigation;