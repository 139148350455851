import { ArrowUpward, AttachMoney, Delete, Edit, MoreVert, PictureAsPdf, Send, Visibility } from '@mui/icons-material';
import { Box, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { FaBell, FaUser } from 'react-icons/fa';
import { RowActionsProps } from '../../@types/Props';
import { AiFillCar } from 'react-icons/ai';
import { useState } from 'react';

const ITEM_HEIGHT = 48;

const RowActions = ({
  showDataAction,
  deleteAction,
  editAction,
  commandAction,
  notificationAction,
  vehicleAction,
  clientAction,
  billAction,
  downloadBillAction,
  sendCommandAction,
  testNotificationAction,
} : RowActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>{
    fullScreen ?
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {
          !!showDataAction ?
          <MenuItem
            key={0}
            onClick={showDataAction}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Visibility className='text-sidebar-icon w-4' />
            </ListItemIcon>
            Visualizar
          </MenuItem> : null
        }
        {
          !!deleteAction ?
          <MenuItem
            key={0}
            onClick={deleteAction}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Delete className='text-sidebar-icon w-4' />
            </ListItemIcon>
            Remover
          </MenuItem> : null
        }
        {
          !!editAction ?
          <MenuItem
            key={1}
            onClick={editAction}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Edit className='text-sidebar-icon w-4' />
            </ListItemIcon>
            Editar
          </MenuItem> : null
        }
        {
          !!commandAction ?
          <MenuItem
            key={2}
            onClick={commandAction}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Send className='text-sidebar-icon w-4' />
            </ListItemIcon>
            Comandos
          </MenuItem> : null
        }
        {
          !!notificationAction ?
          <MenuItem
            key={3}
            onClick={notificationAction}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <FaBell className='text-sidebar-icon w-4' />
            </ListItemIcon>
            Notificações
          </MenuItem> : null
        }
        {
          !!vehicleAction ?
          <MenuItem
            key={4}
            onClick={vehicleAction}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <AiFillCar className='text-sidebar-icon w-4' />
            </ListItemIcon>
            Veículos
          </MenuItem> : null
        }
        {
          !!clientAction ?
          <MenuItem
            key={4}
            onClick={clientAction}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <FaUser className='text-sidebar-icon w-4' />
            </ListItemIcon>
            Clientes
          </MenuItem> : null
        }
        {
          !!billAction ?
          <MenuItem
            key={5}
            onClick={billAction}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <AttachMoney className='text-sidebar-icon w-4' />
            </ListItemIcon>
            Boletos
          </MenuItem> : null
        }
        {
          !!downloadBillAction ?
          <MenuItem
            key={6}
            onClick={downloadBillAction}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <PictureAsPdf className='text-sidebar-icon w-4' />
            </ListItemIcon>
            Boleto
          </MenuItem> : null
        }
        {
          !!sendCommandAction ?
          <MenuItem
            key={7}
            onClick={sendCommandAction}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <ArrowUpward className='text-sidebar-icon w-4' />
            </ListItemIcon>
            Enviar comando
          </MenuItem> : null
        }
        {
          !!testNotificationAction ?
          <MenuItem
            key={8}
            onClick={testNotificationAction}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <FaBell className='text-sidebar-icon w-4' />
            </ListItemIcon>
            Testar Envio
          </MenuItem> : null
        }
      </Menu>
    </> :
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {
        !!showDataAction ?
        <Tooltip arrow placement='bottom' title='Visualizar'>
          <IconButton onClick={showDataAction}>
            <Visibility className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip> : null
      }
      {
        !!deleteAction ?
        <Tooltip arrow placement='bottom' title='Remover'>
          <IconButton onClick={deleteAction}>
            <Delete className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip> : null
      }
      {
        !!editAction ?
        <Tooltip arrow placement='bottom' title='Editar'>
          <IconButton onClick={editAction}>
            <Edit className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip> : null
      }
      {
        !!commandAction ?
        <Tooltip arrow placement='bottom' title='Comandos'>
          <IconButton onClick={commandAction}>
            <Send className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip> : null
      }
      {
        !!notificationAction ?
        <Tooltip arrow placement='bottom' title='Notificações'>
          <IconButton onClick={notificationAction}>
            <FaBell className='text-sidebar-icon w-4'/>
          </IconButton>
        </Tooltip> : null
      }
      {
        !!vehicleAction ?
        <Tooltip arrow placement='bottom' title='Veículos'>
          <IconButton onClick={vehicleAction}>
            <AiFillCar className='text-sidebar-icon w-6'/>
          </IconButton>
        </Tooltip> : null
      }
      {
        !!clientAction ?
        <Tooltip arrow placement='bottom' title='Clientes'>
          <IconButton onClick={clientAction}>
            <FaUser className='text-sidebar-icon w-6'/>
          </IconButton>
        </Tooltip> : null
      }
      {
        !!billAction ?
        <Tooltip arrow placement='bottom' title='Boletos'>
          <IconButton onClick={billAction}>
            <AttachMoney className='text-sidebar-icon w-6'/>
          </IconButton>
        </Tooltip> : null
      }
      {
        !!downloadBillAction ?
        <Tooltip arrow placement='bottom' title='Boleto'>
          <IconButton onClick={downloadBillAction}>
            <PictureAsPdf className='text-sidebar-icon w-6'/>
          </IconButton>
        </Tooltip> : null
      }
      {
        !!sendCommandAction ?
        <Tooltip arrow placement='bottom' title='Enviar comando'>
          <IconButton onClick={sendCommandAction}>
            <ArrowUpward className='text-sidebar-icon w-6'/>
          </IconButton>
        </Tooltip> : null
      }
      {
        !!testNotificationAction ?
        <Tooltip arrow placement='bottom' title='Testar envio'>
          <IconButton onClick={testNotificationAction}>
            <FaBell className='text-sidebar-icon w-6'/>
          </IconButton>
        </Tooltip> : null
      }
    </Box>
    }</>
  );
};

export default RowActions;