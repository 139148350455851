import MainContentContainer from '../Common/MainContentContainer';
import Header from '../Common/Header';
import DeviceSidebar from './DeviceSidebar';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RootState, commandActions, deviceActions, notificationActions } from '../../store';
import { useAuthContext } from '../../contexts/AuthenticationContext';
import { useDeviceContext } from '../../contexts/DeviceContext';
import { useEffect, useState } from 'react';
import DeviceTable from './DeviceTable';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../contexts/UserContext';
import { useNotificationContext } from '../../contexts/NotificationContext';
import { useCommandContext } from '../../contexts/CommandContext';
import { Device } from '../../@types/Device';
import { DeviceFormValue, TableActionType } from '../../@types/Props';
import DeviceForm from './DeviceForm';
import { useGroupContext } from '../../contexts/GroupContext';
import AlertDialog from '../Common/AlertDialog';
import DeviceNotification from './DeviceNotification';
import DeviceCommand from './DeviceCommand';
import SendCommand from './SendCommand';

const DeviceScreen = () => {
  const dispatch = useDispatch();
  //const users = useSelector((state: RootState) => state.user.items);
  const notifications = useSelector((state: RootState) => state.notification.items);
  const devices = useSelector((state: RootState) => state.device.items);
  const commands = useSelector((state: RootState) => state.command.items);
  const groups = useSelector((state: RootState) => state.group.items);
  const selectedDeviceId = useSelector((state: RootState) => state.device.selectedId);
  const { user } = useAuthContext();
  const { fetchNotificationsByUserId, fetchNotificationsSelectedDevice } = useNotificationContext();
  const { fetchCommandsByUserId, fetchCommandsSelectedDevice } = useCommandContext();
  const { generateNewDevice, generateUpdateDevice, fetchDevicesAndPositions, createDevice, updateDeviceById, deleteDeviceById, fetchDevicesSelectedUser } = useDeviceContext();
  const { fetchGroups } = useGroupContext();
  const {t} = useTranslation();

  const [showDeviceAdd, setShowDeviceAdd] = useState(false);
  const [showDeviceEdit, setShowDeviceEdit] = useState(false);
  const [showDeviceData, setShowDeviceData] = useState(false);
  const [showDeviceDelete, setShowDeviceDelete] = useState(false);
  const [showDeviceCommand, setShowDeviceCommand] = useState(false);
  const [showDeviceNotification, setShowDeviceNotification] = useState(false);
  const [showDeviceSendCommand, setShowDeviceSendCommand] = useState(false);

  const toggleShowDeviceAdd = () => setShowDeviceAdd(!showDeviceAdd);
  const toggleShowDeviceEdit = () => setShowDeviceEdit(!showDeviceEdit);
  const toggleShowDeviceData = () => setShowDeviceData(!showDeviceData);
  const toggleShowDeviceDelete = () => setShowDeviceDelete(!showDeviceDelete);
  const toggleShowDeviceCommand = () => setShowDeviceCommand(!showDeviceCommand);
  const toggleShowDeviceNotification = () => setShowDeviceNotification(!showDeviceNotification);
  const toggleShowDeviceSendCommand = () => setShowDeviceSendCommand(!showDeviceSendCommand);
  
  const updateDevice = {
    action: () => fetchDevicesAndPositions(user.id),
  };

  const addDevice = {
    action: () => toggleShowDeviceAdd(),
    onSubmit: (values: DeviceFormValue) => {
      const newDevice = generateNewDevice(values);
      createDevice(user.id, newDevice, addDevice.close);
    },
    close: () => {
      dispatch(deviceActions.unSelect());
      toggleShowDeviceAdd();
    },
  };

  const editDevice = {
    action: (item: TableActionType) => {
      dispatch(deviceActions.select((item as Device).id))
      toggleShowDeviceEdit();
    },
    onSubmit: (values: DeviceFormValue) => {
      const selectedDevice = selectedDeviceId !== null ? devices[selectedDeviceId] : undefined;
      const newDevice = generateUpdateDevice(selectedDevice as Device, values);
      updateDeviceById(user.id, newDevice, editDevice.close);
    },
    close: () => {
      dispatch(deviceActions.unSelect());
      toggleShowDeviceEdit();
    },
  };

  const showDevice = {
    action: (item: TableActionType) => {
      dispatch(deviceActions.select((item as Device).id))
      toggleShowDeviceData();
    },
    onSubmit: (values: DeviceFormValue) => {
      dispatch(deviceActions.unSelect());
      toggleShowDeviceData();
    },
    close: () => {
      dispatch(deviceActions.unSelect());
      toggleShowDeviceData();
    },
  };

  const deleteDevice = {
    action: (item: TableActionType) => {
      dispatch(deviceActions.select((item as Device).id))
      toggleShowDeviceDelete()
    },
    close: () => {
      dispatch(deviceActions.unSelect());
      toggleShowDeviceDelete();
    },
    onAgree: () => {
      const selectedDevice = selectedDeviceId !== null ? devices[selectedDeviceId] : undefined;
      if (selectedDevice)
        deleteDeviceById(user.id, selectedDevice.id, deleteDevice.close);
    }
  };

  const notificationDevice = {
    action: (item: TableActionType) => {
      dispatch(deviceActions.select((item as Device).id));
      fetchNotificationsSelectedDevice((item as Device).id);
      toggleShowDeviceNotification()
    },
    close: () => {
      dispatch(deviceActions.unSelect());
      dispatch(notificationActions.setSelectedNotifications(null));
      toggleShowDeviceNotification();
    },
  }

  const commandDevice = {
    action: (item: TableActionType) => {
      dispatch(deviceActions.select((item as Device).id));
      fetchCommandsSelectedDevice((item as Device).id);
      toggleShowDeviceCommand()
    },
    close: () => {
      batch(() => {
        dispatch(deviceActions.unSelect());
        dispatch(commandActions.setSelectedCommands(null));
      });
      toggleShowDeviceCommand();
    },
  }

  const sendCommandDevice = {
    action: (item: TableActionType) => {
      dispatch(deviceActions.select((item as Device).id));
      fetchCommandsSelectedDevice((item as Device).id);
      toggleShowDeviceSendCommand()
    },
    close: () => {
      batch(() => {
        dispatch(deviceActions.unSelect());
        dispatch(commandActions.setSelectedCommands(null));
      });
      toggleShowDeviceSendCommand();
    },
  }

  useEffect(() => {
    if (user === null)
      return;
    
    if (Object.entries(groups).length === 0) fetchGroups(user.id);
    //if (Object.entries(notifications).length === 0) fetchNotificationsByUserId(user.id);
    if (Object.entries(commands).length === 0) fetchCommandsByUserId(user.id);
    if (Object.entries(devices).length === 0) fetchDevicesAndPositions(user.id);
  }, [user, groups, commands, devices]);

  useEffect(() => {
    if (user === null)
      return;
    
    if (Object.entries(notifications).length === 0) fetchNotificationsByUserId(user.id);
  }, []);
  
  return (
    <div className='overflow-hidden'>

      <Header title={`${t('tracking')} > ${t('vehicles')}`}/>

      <MainContentContainer>

        <DeviceSidebar/>

        <DeviceTable
          devices={Object.entries(devices).map((value, index) => value[1])}
          actions={{
            showDataAction: showDevice.action,
            updateAction: updateDevice.action,
            addAction: addDevice.action,
            deleteAction: deleteDevice.action,
            editAction: editDevice.action,
            commandAction: commandDevice.action,
            // notificationAction: notificationDevice.action,
            sendCommandAction: sendCommandDevice.action,
          }}
        />

        <DeviceForm
          title='Adicionar veículo'
          subtitle='Insira as informações do veículo e clique em SALVAR para confirmar ou clique em CANCELAR para descartar.'
          onSubmit={addDevice.onSubmit}
          device={undefined}
          opened={showDeviceAdd}
          readonly={false}
          close={addDevice.close}
        />

        <DeviceForm
          title='Editar veículo'
          subtitle='Edite as informações do veículo e clique em ATUALIZAR para confirmar ou clique em CANCELAR para descartar. Deixe a senha em branco caso não queira alterá-la.'
          onSubmit={editDevice.onSubmit}
          device={selectedDeviceId !== null ? devices[selectedDeviceId] : undefined}
          opened={showDeviceEdit}
          readonly={false}
          close={editDevice.close}
        />

        <DeviceForm
          title='Visualizar veículo'
          subtitle='Visualize as informações do veículo e clique em FECHAR para sair da tela.'
          onSubmit={showDevice.onSubmit}
          device={selectedDeviceId !== null ? devices[selectedDeviceId] : undefined}
          opened={showDeviceData}
          readonly={true}
          close={showDevice.close}
        />

        <AlertDialog
          title='Remover veículo'
          text='Deseja remover o veículo? Essa ação é irreversível.'
          opened={showDeviceDelete}
          onAgree={deleteDevice.onAgree}
          onDisagree={deleteDevice.close}
          onClose={deleteDevice.close}
        />

        {/* <DeviceNotification
          title='Notificações veículo'
          subtitle='Selecione as notificações do veículo e clique em SALVAR para confirmar ou clique em CANCELAR para descartar.'
          opened={showDeviceNotification}
          readonly={false}
          close={notificationDevice.close}
        /> */}

        <DeviceCommand
          title='Comandos veículo'
          subtitle='Selecione os comandos do veículo e clique em SALVAR para confirmar ou clique em CANCELAR para descartar.'
          opened={showDeviceCommand}
          readonly={false}
          close={commandDevice.close}
        />

        <SendCommand
          title='Enviar comando'
          subtitle='Selecione o comando a ser enviado e clique em ENVIAR para confirmar o envio ou clique em CANCELAR para sair da tela de comando.'
          opened={showDeviceSendCommand}
          readonly={false}
          close={sendCommandDevice.close}
        />

      </MainContentContainer>
      
    </div>
  );
};

export default DeviceScreen;