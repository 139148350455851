import L from 'leaflet';
import { Popup } from 'react-leaflet';
import { Device } from '../../@types/Device';
import { getTimeDiff, speedFromKnots } from '../../util/util';
import 'leaflet-rotatedmarker';
import { Divider, Grid, Typography } from '@mui/material';
import { BatteryChargingFull, LockOpenRounded, LockRounded, PowerSettingsNewRounded, RoomOutlined, SpeedRounded } from '@mui/icons-material';
import { useState } from 'react';
import { TableActionType } from '../../@types/Props';
import { batch, useDispatch } from 'react-redux';
import { useCommandContext } from '../../contexts/CommandContext';
import { commandActions, deviceActions } from '../../store';
import SendCommand from '../Device/SendCommand';

type MainMapPopupProps = {
  device: Device;
};

const MainMapPopup = ({ device } : MainMapPopupProps) => {
  const dispatch = useDispatch();

  const pos = device.position;

  const { fetchCommandsSelectedDevice } = useCommandContext();
  
  const [showDeviceSendCommand, setShowDeviceSendCommand] = useState(false);
  
  const toggleShowDeviceSendCommand = () => setShowDeviceSendCommand(!showDeviceSendCommand);

  const sendCommandDevice = {
    action: (item: TableActionType) => {
      dispatch(deviceActions.select((item as Device).id));
      fetchCommandsSelectedDevice((item as Device).id);
      toggleShowDeviceSendCommand()
    },
    close: () => {
      batch(() => {
        dispatch(deviceActions.unSelect());
        dispatch(commandActions.setSelectedCommands(null));
      });
      toggleShowDeviceSendCommand();
    },
  }
  
  return (
    <>
    <Popup
      closeButton={false}
      autoPan={false}
      autoPanPaddingTopLeft={L.point(15,15)} 
      autoPanPaddingBottomRight={L.point(15,15)}
    >
      <Grid container justifyContent={'space-between'} style={{flexFlow:"row", paddingBottom: 5}} className='select-none'>
        <PowerSettingsNewRounded
          className={
            pos !== null && pos.attributes !== null && pos.attributes !== undefined && 
            pos.attributes.ignition ? "text-limegreen" : "text-red-original"
          }
        />
        <span className='pr-6 pl-4' style={{flexFlow:"column", marginTop: -15, marginBottom: -7}}>
          <Typography className='text-center' style={{fontSize: 14}}>
            {device.attributes.placa}
          </Typography>
          <Typography style={{fontSize: 10, color: "gray", marginTop: -15, textAlign: 'center'}}>
            {getTimeDiff(device.lastUpdate)}
          </Typography>
          <Typography style={{fontSize: 10, color: "gray", marginTop: -10, textAlign: 'center'}}>
            {device.name.length > 25 ? device.name.slice(0, 25 - 1) + "…" : device.name}
          </Typography>
        </span>
        {
          pos !== null && pos !== undefined && pos.attributes !== undefined && pos.attributes !== null &&
          pos.attributes.blocked !== undefined ? (
            pos.attributes.blocked ?
            <span style={{flexFlow:"column", color: "red"}} className='hover:cursor-pointer' onClick={() => sendCommandDevice.action(device)}>
              <LockRounded />
            </span> :
            <span style={{flexFlow:"column", color: "lightseagreen"}} className='hover:cursor-pointer' onClick={() => sendCommandDevice.action(device)}>
              <LockOpenRounded />
            </span>
          ) : (
            pos !== null && pos !== undefined && pos.attributes !== undefined && pos.attributes !== null &&
            (
              pos.attributes.result === "Cut off the fuel supply:Success!" || pos.attributes.result === "Cut off the fuel supply: Success!" ||
              pos.attributes.result === "Hased Cut off the fuel supply:Success!" || pos.attributes.result === "Hased Cut off the fuel supply: Success!"
            ) ?
            <span style={{flexFlow:"column", color: "red"}} className='hover:cursor-pointer' onClick={() => sendCommandDevice.action(device)}>
              <LockRounded />
            </span> :
            pos !== null && pos !== undefined && pos.attributes !== undefined && pos.attributes !== null &&
            (pos.attributes.result === "Restore fuel supply:Success!" || pos.attributes.result === "Restore fuel supply: Success!") ?
            <span style={{flexFlow:"column", color: "lightseagreen"}} className='hover:cursor-pointer' onClick={() => sendCommandDevice.action(device)}>
              <LockOpenRounded />
            </span> :
            <span style={{flexFlow:"column", color: "lightseagreen"}} className='hover:cursor-pointer' onClick={() => sendCommandDevice.action(device)}>
              <LockOpenRounded />
            </span>
          )
        }
        <span className='flex-col text-[lightseagreen] text-center mr-[5px]'>
          <BatteryChargingFull/>
          <Typography
            style={{fontSize: 10, margin: -4}}
          >
            {
                pos !== null && pos !== undefined &&
                pos.attributes !== null && pos.attributes !== undefined &&
                pos.attributes.adc1 !== null && pos.attributes.adc1 !== undefined ?
                  `${pos.attributes.adc1.toFixed(2)} V`
                :
                pos !== null && pos !== undefined &&
                pos.attributes !== null && pos.attributes !== undefined &&
                pos.attributes.batteryLevel !== null && pos.attributes.batteryLevel !== undefined ?
                  `${pos.attributes.batteryLevel.toFixed(2)} %`
                : 
                'N/A'
              }
          </Typography>
        </span>
        <span style={{flexFlow:"column", color: "lightseagreen", paddingLeft: 6}}>
          <SpeedRounded />
          <Typography
            style={{fontSize: 10, margin: -4}}
          >
            {
              pos !== null && pos !== undefined && pos.speed !== 0 ?
              `${speedFromKnots(pos.speed, 'kmh')} km/h`
              :
              '0 km/h'
            } 
          </Typography>
        </span>
      </Grid>
      <Divider />
      <Grid container justifyContent={"space-evenly"} style={{flexFlow:"row", marginTop: 10}} className='select-none'>
        <RoomOutlined/>
        <Typography variant="inherit" style={{marginLeft: 15, marginTop: 0, marginBottom: 0}}>
          {
          pos !== null && pos.address !== null && pos.address !== undefined ?
          pos.address.replace("Brazil", "Brasil") :
          "Não foi possível obter o endereço a partir dessa coordenada"
          }
        </Typography>
        
      </Grid>
    </Popup>
    
    <SendCommand
      title='Enviar comando'
      subtitle='Selecione o comando a ser enviado e clique em ENVIAR para confirmar o envio ou clique em CANCELAR para sair da tela de comando.'
      opened={showDeviceSendCommand}
      readonly={false}
      close={sendCommandDevice.close}
    />
    </>
  )
};

export default MainMapPopup;